<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <h3>Service Call</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-form
            ref="formCustomer"
            v-model="validFormCustomer"
            lazy-validation
        >

            <v-tabs
                v-model="tab"
                fixed-tabs
            >
                <v-tab key="1">
                    Details
                </v-tab>
                <v-tab key="2">
                    History
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">

                <!-- DETAILS -->
                <v-tab-item key="1">
                    <br />

                    <v-row>
                        <v-col 
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                        >
                            <label>Already a customer?</label>
                            <v-radio-group 
                                v-model="serviceCallsRequest.isCustomer" 
                                row
                                @click="getLists()"
                            >
                                <v-radio
                                    label="Yes"
                                    :value="1"
                                    color="var(--color__main)"
                                ></v-radio>
                                <v-radio
                                    label="No"
                                    :value="0"
                                    color="var(--color__main)"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        
                        <v-col 
                            v-if="serviceCallsRequest.isCustomer === 1"
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Customer</label>
                            <v-combobox
                                v-model="customerSelected"
                                :items="listCustomer" 
                                :rules=[validations.required]
                                item-text="name"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                @change="searchProjects"
                            ></v-combobox>
                        </v-col>

                        <v-col 
                            style="display: flex; align-items: center;"
                            v-if="serviceCallsRequest.isCustomer === 1"
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >                        
                            <v-radio-group 
                                v-model="serviceCallsRequest.redFlag" 
                                row
                            >
                                <v-radio
                                    label="No Flag"
                                    :value="0"
                                    color="var(--color__main)"
                                ></v-radio>
                                <v-radio
                                    label="Yellow Flag"
                                    :value="1"
                                    color="var(--color__yellow)"
                                ></v-radio>
                                <v-radio
                                    label="Red Flag"
                                    :value="2"
                                    color="var(--color__red)"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>

                        <v-col 
                            v-if="serviceCallsRequest.isCustomer !== 1"
                            cols="12"
                            lg="4"
                            md="4"
                            sm="12"
                        >
                            <label>Origin</label>
                            <v-combobox
                                v-model="serviceCallsRequest.customerOriginSelected"
                                :items="listCustomerOrigin"
                                :rules=[validations.required]
                                item-text="description"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                        
                        <v-col 
                            v-if="serviceCallsRequest.isCustomer !== 1"
                            cols="12"
                            lg="6"
                            md="8"
                            sm="12"
                        >
                            <label>Name</label>
                            <v-text-field
                                v-model="serviceCallsRequest.name" 
                                :rules=[validations.required]
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="serviceCallsRequest.isCustomer === 1"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Projects</label>
                            <v-combobox
                                v-model="serviceCallsRequest.projectSelected"
                                :items="listProjects" 
                                :rules=[validations.required]
                                item-text="description"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="serviceCallsRequest.isCustomer !== 1"
                    >
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="8"
                        >
                            <label>Address</label>
                            <v-text-field
                                v-model="serviceCallsRequest.address" 
                                :rules=[validations.required]
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="4"
                        >
                            <label>Zip Code</label>
                            <v-text-field
                                v-model="serviceCallsRequest.zipCode" 
                                :rules=[validations.required]
                                v-mask="'#########'"
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="serviceCallsRequest.isCustomer !== 1"
                    >
                        <v-col  
                            cols="12"
                            lg="3"
                            md="3"
                            sm="12"
                        >
                            <label>Category</label>
                            <v-combobox
                                v-model="serviceCallsRequest.addressCategorySelected"
                                :items="listAddressCategory"
                                :rules=[validations.required]
                                item-text="description"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                        <v-col  
                            cols="12"
                            lg="3"
                            md="3"
                            sm="4"
                        >
                            <label>State</label>
                            <v-combobox
                                v-model="serviceCallsRequest.stateSelected"
                                :items="listState"
                                :rules=[validations.required]
                                item-text="codeDescription"
                                item-value="id"
                                clearable
                                outlined
                                @change="getListCityByState();"
                                dense
                            ></v-combobox>
                        </v-col>

                        <v-col  
                            cols="12"
                            lg="6"
                            md="6"
                            sm="8"
                        >
                            <label>City</label>
                            <v-combobox
                                v-model="serviceCallsRequest.citySelected"
                                :items="listCity"
                                :rules=[validations.required]
                                item-text="codeStateDescriptionCity"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="serviceCallsRequest.isCustomer !== 1"
                    >
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Phone</label>
                            <v-text-field
                                v-model="serviceCallsRequest.phone" 
                                :rules=[validations.required]
                                v-mask="'+1 (###) ###-####'"
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Email</label>
                            <v-text-field
                                v-model="serviceCallsRequest.email" 
                                :rules=[validations.required,validations.email]
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <v-menu
                                ref="mnuEstimatedDate"
                                v-model="mnuEstimatedDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label>Schedule Date</label>
                                    <v-text-field
                                        v-model="estimatedDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        @blur="estimatedDate = parseDate(estimatedDateFormatted)"
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="estimatedDate"
                                    no-title
                                    @input="mnuEstimatedDate = false"
                                    :min="today"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Responsible</label>
                            <v-combobox
                                v-model="serviceCallsRequest.responsibleSelected"
                                :items="listUser"
                                item-text="name"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Scope of work</label>
                            <v-textarea
                                v-model="serviceCallsRequest.notes"
                                :rules=[validations.required]
                                single-line
                                rows="4"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <canvas id="canvas" hidden></canvas>
                            <label>Photos</label>
                            <v-file-input
                                v-model="serviceCallsFilesPhotos" 
                                accept="image/*"
                                small-chips
                                multiple
                                outlined
                                placeholder="Click here to add"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                                @change="resizePreviewImage(serviceCallsFilesPhotos, serviceCallsPhotos)"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col
                            v-for="(itemPhoto, index) in serviceCallsPhotos.filter(pht => pht.deleted == false)"
                            :key="index"
                            cols="12"
                            lg="1"
                            md="4"
                            sm="12"
                        >
                            <v-card max-width="200">
                                <v-img
                                    :src="itemPhoto.src"
                                    :lazy-src="itemPhoto.src"
                                    aspect-ratio="1"
                                    max-height="200"
                                    max-width="200"
                                >
                                    <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                    </template>
                                </v-img>
                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(itemPhoto)">mdi mdi-delete</v-icon>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <!-- HISTORY -->
                <v-tab-item key="2">
                    <br />

                    <v-row>
                        <v-col
                            style="text-align: right"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2"
                                        v-on="on"
                                        @click="showDialogNoteAndClearFields()"
                                    >
                                        Add New Note
                                    </v-btn>
                                </template>
                                <span>Add New Note</span>
                            </v-tooltip>
                        </v-col>
                        <v-col  
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <br />
                            <v-data-table
                                :headers="headersNote"
                                :items="listHistory"
                                no-data-text="No notes registered"
                                hide-default-footer
                            >
                                <template v-slot:item.action="{item}">
                                    <ActionList 
                                        :id="item.idServiceCallsReference" 
                                        :showButtons="{
                                            edit: true,
                                            delete: true
                                        }"
                                        @customActionEdit="showDialogNoteAndFillFields"
                                        @confirmDelete="confirmNoteDelete" />
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-dialog
                        v-model="showDialogNote"
                        transition="dialog-top-transition"
                        persistent
                        width="1000"
                        :fullscreen="$vuetify.breakpoint.mobile"
                    >
                    
                        <v-card>
                            <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                                Note
                            </v-card-title>

                            <v-card-text style="margin-top: 20px;">
                                <v-form
                                    ref="formNote"
                                    v-model="validFormNote"
                                    lazy-validation
                                >
                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <label>New Note</label>
                                            
                                            <v-textarea
                                                v-model="note"
                                                :rules=[validations.required]
                                                single-line
                                                rows="3"
                                                row-height="30"
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                        >
                                            <label>Pictures</label>
                                            <v-file-input
                                                v-model="serviceCallsNotesFilesPhotos" 
                                                accept="image/*"
                                                small-chips
                                                multiple
                                                outlined
                                                placeholder="Click here to add"
                                                prepend-inner-icon="mdi-paperclip"
                                                prepend-icon=""
                                                @change="resizePreviewImage(serviceCallsNotesFilesPhotos, serviceCallsNotesPhotos)"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            v-for="(itemPhoto, index) in serviceCallsNotesPhotos.filter(pht => pht.deleted == false)"
                                            :key="index"
                                            cols="12"
                                            lg="2"
                                            md="4"
                                            sm="12"
                                        >
                                            <v-card max-width="200">
                                                <v-img
                                                    :src="itemPhoto.src"
                                                    :lazy-src="itemPhoto.src"
                                                    aspect-ratio="1"
                                                    max-height="200"
                                                    max-width="200"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(itemPhoto)">mdi mdi-delete</v-icon>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                </v-form>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <div style="width: 100%; text-align: center;">
                                    <v-btn
                                        class="mx-2 buttonCancelDialog"
                                        style=""
                                        outlined
                                        @click="showDialogNote = false"
                                    >
                                        Cancel
                                    </v-btn>

                                    <v-btn
                                        class="mx-2"
                                        outlined
                                        @click="saveNote()"
                                    >
                                        Save
                                    </v-btn>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>


                </v-tab-item>

            </v-tabs-items>

            <br />

            <ActionButtons
                :request="serviceCallsRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required, validEmail } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionList from "@/components/Layout/ActionList";
	import * as moment from 'moment';
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT 
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            ActionList,
            ActionButtons
        },

        mixins: [Helpers],

        data: vm => ({

            tab: null,

            validFormNote: true,

            validFormCustomer: true,
            listCustomerOrigin: [],
            customerSelected: null,

            serviceCallsRequest: {
                id: 0,
                dateRegister: 0,
                idUserRegister: 0,
                isCustomer: 0,
                addressSelected: null,
                idCustomer: 0,
                redFlag: 0,
                name: "",
                customerOriginSelected: null,
                zipCode: "",
                address: "",
                idAddressCategory: 0,
                idCity: 0,
                stateSelected: null,
                citySelected: null,
                phone: "",
                email: "",
                estimatedDate: "",
                isGatedCommunity: 0,
                dogOnSite: 0,
                community: "",
                notes: "",
                status: "",
                idUserResponsible: ""
            },

            listUser: [],
            listProjects: [],

            listCustomer: [],
            listAddress: [],
            listAddressCategory: [],
            listCity: [],
            listCityProject: [],
            listState: [],

            today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

            estimatedDate: vm.today,
            estimatedDateFormatted: vm.formatDate(vm.today),
            mnuEstimatedDate: false,

            serviceCallsFilesPhotos: [],
            serviceCallsPhotos: [],
            
            //NOTE
            showDialogNote: false,
            note: "",
            itemNote: null,

            serviceCallsNotesFilesPhotos: [],
            serviceCallsNotesPhotos: [],

            listHistory: [],
            headersNote: [
                { text: "Date", value: "dateRegisterFormatted", sortable: true },
                { text: "Username", value: "userName", sortable: true },
                { text: "Note", value: "description", sortable: true },
                { text: "Qtd Pictures", value: "qtdPictures", sortable: false, align: "center" },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            loading: false,

            validations: {
                required: required,
                email: validEmail,
            }
        }),

        watch: {
            estimatedDate () {
                this.estimatedDateFormatted = this.formatDate(this.estimatedDate)
            },
        },

        props: {
            id: {
                default: 0
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState', menuGetter: 'menuState' })
        },

        methods: {

            async searchProjects() {

                if (this.customerSelected != null && this.customerSelected != undefined) {
                    const search = this.customerSelected.name;

                    this.isLoading = true;

                    this.listProjects = await this.$store.dispatch("serviceModule/ListProjectCompact", search);

                    this.isLoading = false;
                }
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            async confirmPhotoDelete(itemPhotoToDelete) {

                this.itemPhotoToDelete = itemPhotoToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this file [${itemPhotoToDelete.fileName}], confirm your decision?`,
                    methodConfirm: this.deletePhoto,
                    params: 0
                };
            },

            async deletePhoto() {

                this.itemPhotoToDelete.deleted = true;
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("serviceCallsModule/GetById", this.id);

                    const serviceCallsResponse = {...response.result};
                    this.listHistory = serviceCallsResponse.listHistory;

                    const idServiceProject = serviceCallsResponse.idServiceProject;
                    this.customerSelected = serviceCallsResponse.customerSelected;
                    await this.searchProjects();
                    const projectSelected = this.listProjects.filter(prj => prj.id == idServiceProject)[0];
                                                
                    this.serviceCallsFilesPhotos = [];
                    this.serviceCallsPhotos = [];

                    const listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.SERVICE_CALLS.value, idKey: this.id });
                    const listPhotos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SERVICE_CALLS_PICTURES.value);  
                    listPhotos.forEach(itemPhoto => {

                        this.serviceCallsPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            type: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })

                    })

                    this.serviceCallsRequest = serviceCallsResponse;

                    this.serviceCallsRequest.estimatedDate = serviceCallsResponse.estimatedDateFormatted;
                    this.estimatedDate = this.parseDate(serviceCallsResponse.estimatedDateFormatted);
                    this.estimatedDateFormatted = serviceCallsResponse.estimatedDateFormatted;
                    this.serviceCallsRequest.responsibleSelected = serviceCallsResponse.userResponsibleSelected;
                    this.serviceCallsRequest.projectSelected = projectSelected;

                    await this.listHistory.forEach(async itemHistory => {

                        let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.SERVICE_CALLS.value, idKey: itemHistory.id });

                        itemHistory.serviceCallsNotesPhotos = [];

                        let listPhotos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SERVICE_CALLS_NOTES_PICTURES.value);  
                        await listPhotos.forEach(async itemPhoto => {

                            itemHistory.serviceCallsNotesPhotos.push({
                                id: itemPhoto.id,
                                file: new File([], itemPhoto.fileName),
                                fileName: itemPhoto.fileNameShort,
                                deleted: false,
                                src: `data:image/jpg;base64,${itemPhoto.file}`
                            })
                        })

                        itemHistory.qtdPictures = itemHistory.serviceCallsNotesPhotos.length;
                    });

                    setTimeout(() => {
                        this.customerSelected = serviceCallsResponse.customerSelected;
                    }, 200);
                }
                else {
                    this.serviceCallsRequest.stateSelected = {
                        id: 13,
                        codeDescription: "FL - Florida"
                    }

                    this.getListCityByState();
                }

            },

            async getListCityByState() {
                
                this.serviceCallsRequest.citySelected = null;

                let idState = 0;

                if (this.serviceCallsRequest.stateSelected != null && this.serviceCallsRequest.stateSelected != undefined) {
                    idState = this.serviceCallsRequest.stateSelected.id;
                }

                this.listCity = await this.$store.dispatch("cityModule/List", idState);
            },

            async getLists() {
                
                let listCustomerResponse = await this.$store.dispatch("customerModule/List");
                this.listCustomer = listCustomerResponse.filter(cus => cus.status == 1);
                this.listCustomerOrigin = await this.$store.dispatch("customerOriginModule/List");
                
                this.listAddressCategory = await this.$store.dispatch("addressCategoryModule/List");
                this.listState = await this.$store.dispatch("stateModule/List");
                this.listUser = await this.$store.dispatch("userModule/List");
            },

            async uploadFiles(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.SERVICE_CALLS.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            //NOTE
            async clearFieldsNote() {

                this.note = "";
                this.itemNote = null;
                this.serviceCallsNotesFilesPhotos = [];
                this.serviceCallsNotesPhotos = [];
            },

            async showDialogNoteAndClearFields()  {

                this.clearFieldsNote();
                this.showDialogNote = true;
            },

            async showDialogNoteAndFillFields(id) {
                this.clearFieldsNote();
                this.itemNote = this.listHistory.filter(not => not.idServiceCallsReference == id)[0];

                if (this.itemNote != null && this.itemNote != undefined) {
                    
                    this.note = this.itemNote.description;
                    this.serviceCallsNotesPhotos = this.itemNote.serviceCallsNotesPhotos;
                    this.showDialogNote = true;
                }
            },

            async saveNote() {

                await this.$refs.formNote.validate();

                if (this.validFormNote === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the notes form. Please review!");
                    return false;
                }
                else {

                    if (this.itemNote == null) {

                        this.listHistory.push({
                            id: 0,
                            idServiceCallsReference: parseFloat(Math.random().toFixed(5)),
                            dateRegisterFormatted: moment().format("MM/DD/YYYY HH:MM"),
                            userName: this.userLoggedGetters.name,
                            description: this.note.trim(),
                            serviceCallsNotesPhotos: this.serviceCallsNotesPhotos,
                            qtdPictures: this.serviceCallsNotesPhotos.length,
                            newRegister: true
                        })
                    }
                    else {
                        this.itemNote.description = this.note.trim();
                        this.itemNote.serviceCallsNotesPhotos = this.serviceCallsNotesPhotos;
                        this.itemNote.qtdPictures = this.serviceCallsNotesPhotos.length;
                    }

                    this.$forceUpdate();

                    this.showDialogNote = false;
                }
            },

            confirmNoteDelete(id) {

                let index = 0
                this.listHistory.forEach(itemHistory => {
                    if (itemHistory.idServiceCallsReference == id) {

                        this.listHistory.splice(index, 1);
                    }
                    index++;
                });
            },

            async savePhotos() {

                let allResult = []

                this.serviceCallsPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadFiles(itemPhoto.id, this.serviceCallsRequest.id, itemPhoto.file, TYPE_DOCUMENT.SERVICE_CALLS_PICTURES.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.SERVICE_CALLS_PICTURES.description,
                            message: result.message
                        })
                    }
                })

                this.listHistory.forEach(async itemHistory => {
                    itemHistory.serviceCallsNotesPhotos.forEach(async itemPhoto => {

                        let result = await this.uploadFiles(itemPhoto.id, itemHistory.id, itemPhoto.file, TYPE_DOCUMENT.SERVICE_CALLS_NOTES_PICTURES.value, itemPhoto.deleted);

                        if (result.success == false) {
                            allResult.push({
                                typeDocument: TYPE_DOCUMENT.SERVICE_CALLS_NOTES_PICTURES.description,
                                message: result.message
                            })
                        }
                    })
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `Error to upload photos: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async validateForm() {
                await this.$refs.formCustomer.validate();

                if (this.validFormCustomer === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in this step. Please review!");
                    return false;
                }
                else {
                    return true;
                }
            },

            async cancel() {
                this.$router.push({ path: "/service/callsList" });
            },

            async save() {

                if (await this.validateForm()) {

                    this.showLoading();

                    this.serviceCallsRequest.id = this.id;
                    this.serviceCallsRequest.estimatedDate = this.estimatedDate;
                    this.serviceCallsRequest.idUserResponsible = 0;

                    if (this.serviceCallsRequest.isCustomer === 1) {
                        this.serviceCallsRequest.idCustomer = this.customerSelected.id;
                    }
                    else {
                        this.serviceCallsRequest.idAddressCategory = this.serviceCallsRequest.addressCategorySelected.id;
                        this.serviceCallsRequest.idCity = this.serviceCallsRequest.citySelected.id;
                        
                        if (this.serviceCallsRequest.customerOriginSelected != null && this.serviceCallsRequest.customerOriginSelected != undefined) {
                            this.serviceCallsRequest.idCustomerOrigin = this.serviceCallsRequest.customerOriginSelected.id;
                        }
                    }

                    if (this.serviceCallsRequest.projectSelected != null && this.serviceCallsRequest.projectSelected != undefined) {
                        this.serviceCallsRequest.idServiceProject = this.serviceCallsRequest.projectSelected.id;
                    }

                    if (this.serviceCallsRequest.responsibleSelected != null && this.serviceCallsRequest.responsibleSelected != undefined) {
                        this.serviceCallsRequest.idUserResponsible = this.serviceCallsRequest.responsibleSelected.id;
                    }

                    this.serviceCallsRequest.listHistory = [...this.listHistory];

                    const result = await this.$store.dispatch("serviceCallsModule/CreateUpdate", this.serviceCallsRequest);

                    this.serviceCallsRequest = result.serviceCallResponse;

                    this.serviceCallsRequest.listHistory.forEach(itemHistoryResponse => {
                        this.listHistory.forEach(itemHistoryLocal => {
                            if (itemHistoryLocal.idServiceCallsReference == itemHistoryResponse.idServiceCallsReference) {
                                itemHistoryLocal.id = itemHistoryResponse.id;
                            }
                        });
                    });

                    if (await this.savePhotos()) {

                        if (result.success === true) {
                            this.showToast("success", "Success!", result.message);
                            this.cancel();
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
            },            
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

</style>